@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_detail {
    padding: 32px 0;

    &:before {
        content: '';
        display: block;
        width: calc(50vw - #{$global-width} / 12 * 2);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;

        @include breakpoint(medium down) {
            display: none;
        }

        @include breakpoint(xlarge) {
            width: calc(50vw - #{$global-width} / 12 * 4);
        }
    }

    .grid-container {
        position: relative;
        z-index: 1;
    }

    .content {
        color: $color-white;
        position: relative;

        @include breakpoint(medium down) {
            &:before {
                content: '';
                width: 150vw;
                height: calc(100% + 160px);
                display: block;
                position: absolute;
                top: -32px;
                left: -50vw;
                z-index: -1;
            }
        }

        @include breakpoint(large) {
            padding-right: 32px;
        }

        @include breakpoint(xxlarge) {
            padding-right: 0;
        }
    }

    .rating {
        display: flex;
        align-items: center;
    }

    .star-rating {
        margin: 24px 0;
    }

    .rating-number {
        margin-left: 16px;
    }

    .course_details {
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;

        .course_details__item {
            display: flex;

            @include breakpoint(large down) {
                width: 50%;
                margin-top: 16px;
            }

            @include breakpoint(xlarge) {
                margin-right: 24px;
            }
        }

        svg {
            margin-right: 8px;
        }

        span {
            display: block;
        }
    }

    .course-sidebar {
        position: relative;

        @include breakpoint(medium down) {
            &:before,
            &:after {
                content: '';
                display: block;
                width: 150vw;
                height: calc(100% + 48px);
                position: absolute;
                top: 0;
                left: -50vw;
            }

            &:before {
                z-index: -3;
            }

            &:after {
                z-index: -2;
            }
        }

        @include breakpoint(large down) {
            margin-top: 48px;
        }
    }

    .course-sidebar__inner {
        background-color: $color-white;
        padding: 24px;
    }

    .course-dates {
        margin-bottom: 24px;
    }

    .course-dates__title {
        margin-bottom: 8px;
    }

    .course-dates__item {
        display: flex;
        margin: 4px 0;

        .date,
        button {
            width: 35%;
            text-align: left;
        }

        .location,
        a {
            width: 40%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .status {
            padding: 4px;
            height: 20px;
        }
    }

    .button {
        width: 100%;
        text-align: center;

        &:after {
            display: none;
        }
    }

    .button--primary {
        margin-bottom: 12px;
    }

    .button--tertiary {
        margin-top: 12px;
    }

    .modal form {
        max-height: 80vh;
        overflow: scroll;

        @include breakpoint(large) {
            max-height: 35vh;
        }
    }

    .incompany_data {
        img {
            width: 100%;
        }

        .title-department {
            margin: 24px 0 12px;
            display: block;
        }

        .telephone {
            margin-bottom: 12px;
        }

        .email,
        .telephone {
            display: flex;
            align-items: center;

            svg {
                width: 24px;
                height: 24px;
            }

            a {
                margin-left: 12px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .button {
            width: 100%;
            text-align: center;
            margin-bottom: 12px;

            &:nth-of-type(1) {
                margin: 38px 0 12px 0;
            }
        }
    }
}

.sticky-buttons-course .button + .button {
    margin-left: 8px;

    @include breakpoint(medium) {
        margin-left: 24px;
    }
}

;@import "sass-embedded-legacy-load-done:338";