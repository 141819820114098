@import "../../../src/scss/themes/opleiding/abstracts/custom_variables";
@import "../../../src/scss/themes/opleiding/abstracts/custom_mixins";
@import "_default/block_hero_detail.scss";

.block_hero_detail {
    @include breakpoint(large) {
        background: linear-gradient(90deg, $color-primary calc(50% + #{$global-width} / 12 * 2), $color-secondary-light calc(50% + #{$global-width} / 12 * 2));
    }

    @include breakpoint(xlarge) {
        background: linear-gradient(90deg, $color-primary calc(50% + #{$global-width} / 12 * 4), $color-secondary-light calc(50% + #{$global-width} / 12 * 4));
    }

    &:before {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 224 224' style='enable-background:new 0 0 224 224;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,10.7v10.7V32h10.7V21.3V10.7V0H0V10.7z'/%3E%3Cpath class='st0' d='M21.3,10.7L10.7,21.3L21.3,32L32,21.3L21.3,10.7z'/%3E%3Cpath class='st0' d='M112,10.7v10.7V32h10.7V21.3V10.7V0H112V10.7z'/%3E%3Cpath class='st0' d='M133.3,10.7l-10.7,10.7L133.3,32L144,21.3L133.3,10.7z'/%3E%3Cpath class='st0' d='M60,122.7v10.7V144h10.7v-10.7v-10.7V112H60V122.7z'/%3E%3Cpath class='st0' d='M81.3,122.7l-10.7,10.7L81.3,144L92,133.3L81.3,122.7z'/%3E%3Cpath class='st0' d='M172,122.7v10.7V144h10.7v-10.7v-10.7V112H172V122.7z'/%3E%3Cpath class='st0' d='M193.3,122.7l-10.7,10.7l10.7,10.7l10.7-10.7L193.3,122.7z'/%3E%3C/svg%3E%0A");
        background-repeat: repeat;
        background-attachment: fixed;
        background-size: 224px auto;
        opacity: 0.25;
    }

    @include breakpoint(medium down) {
        .content:before {
            background-color: $color-primary;
        }
    }

    .rating-number {
        color: $color-white;
    }

    .course_details {
        .title {
            font-size: $font-m;
            font-weight: $bold;
        }

        .subtitle {
            font-size: $font-s;
        }
    }

    @include breakpoint(medium down) {
        .course-sidebar {
            &:before {
                background-color: $color-secondary-light;
            }

            &:after {
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 224 224' style='enable-background:new 0 0 224 224;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,10.7v10.7V32h10.7V21.3V10.7V0H0V10.7z'/%3E%3Cpath class='st0' d='M21.3,10.7L10.7,21.3L21.3,32L32,21.3L21.3,10.7z'/%3E%3Cpath class='st0' d='M112,10.7v10.7V32h10.7V21.3V10.7V0H112V10.7z'/%3E%3Cpath class='st0' d='M133.3,10.7l-10.7,10.7L133.3,32L144,21.3L133.3,10.7z'/%3E%3Cpath class='st0' d='M60,122.7v10.7V144h10.7v-10.7v-10.7V112H60V122.7z'/%3E%3Cpath class='st0' d='M81.3,122.7l-10.7,10.7L81.3,144L92,133.3L81.3,122.7z'/%3E%3Cpath class='st0' d='M172,122.7v10.7V144h10.7v-10.7v-10.7V112H172V122.7z'/%3E%3Cpath class='st0' d='M193.3,122.7l-10.7,10.7l10.7,10.7l10.7-10.7L193.3,122.7z'/%3E%3C/svg%3E%0A");
                background-repeat: repeat;
                background-attachment: fixed;
                background-size: 224px auto;
                opacity: 0.25;
            }
        }
    }

    .course-sidebar__inner {
        color: $color-primary;
    }

    .course-dates__title {
        @include font-text-s;
        font-weight: $bold;
    }

    .course-dates__item {
        @include font-text-xxs;

        .status {
            background-color: $color-secondary-light;
            color: $color-white;
            font-size: $font-xxxxs;
            font-weight: $bold;
            line-height: 100%;
            text-transform: uppercase;
        }

        a,
        button {
            color: $color-tertiary;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        button {
            cursor: pointer;
        }
    }

    .incompany_data {
        .title-department {
            font-weight: $bold;
            color: $color-primary;
        }
    }
}

.dates-list {
    overflow: scroll;
    max-height: 40vh;

    .dates-list__item.js-active {
        box-shadow: 0px 0px 8px 2px rgba($color-black, 0.05);
        background-color: lighten($color-black, 94%);
    }
}
